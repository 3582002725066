import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import * as serviceWorker from "./serviceWorker";

//css files
import "./assets/css/bootstrap.min.css";
import "./assets/css/style.css";
import "./assets/css/slick.css";
import "./assets/css/magnific-popup.min.css";

const App = React.lazy(() => import("./App"));

const loader = () => {
	return (
		<div id="loader" className="vertical--center">
			<svg className="spinner" viewBox="0 0 50 50">
				<circle
					className="path"
					cx="25"
					cy="25"
					r="20"
					fill="none"
					strokeWidth="5"
				></circle>
			</svg>
		</div>
	);
}

ReactDOM.render(
	<React.StrictMode>
		<div className="Page">
			<Suspense fallback={loader()}>
				<App />
			</Suspense>
		</div>
	</React.StrictMode>,
	document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
